<template>
  <div class="main-content">
    <h3 class="marginBottom20">{{ this.id ? "编辑商品" : "新增商品" }}</h3>
    <el-tabs v-model="active" :before-leave="beforeLeave">
      <el-tab-pane label="基本信息" name="1">
        <createOne ref="createOne" @onload="onload" />
        <div class="flex align-items justify-content-center marginTop20">
          <el-button size="medium" type="primary" @click="back"
            >返回</el-button
          >
          <el-button size="medium" type="primary" @click="nextStep('1')"
            >下一步</el-button
          >
        </div>
      </el-tab-pane>
      <el-tab-pane label="规格信息" name="2">
        <createTwo ref="createTwo" />
        <div class="flex align-items justify-content-center marginTop20">
          <el-button size="medium" type="primary" @click="back"
            >返回</el-button
          >
          <el-button size="medium" @click="previousStep('1')">上一步</el-button>
          <el-button
            size="medium"
            :disabled="submitType"
            type="primary"
            @click="handleSubmit"
            >提交</el-button
          >
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import createOne from "./components/createOne.vue";
import createTwo from "./components/createTwo.vue";
import createThree from "./components/createThree.vue";
import { add, update, view } from "@/api/supplierGoods/goods";
import { activityGoodsAdd, activityGoodsUp,activityView } from "@/api/market/activity";
export default {
  name: "info", 
  components: {
    createOne, 
    createTwo, 
    createThree,
  },
  data() {
    return {
      active: "1",
      id: this.$route.query.id, //门店的商品id
      goodsId: this.$route.query.goodsId, //平台商品库的id
      formOne: {},
      formTwo: {},
      formThree: {},
      submitType: false, // 避免重复点击
      storeId: this.$store.state.user.userInfo.storeId,
      activityId: this.$route.query.activityId,
      storeGoodsId: this.$route.query.storeGoodsId,
      operationType:this.$route.query.operationType,
    };
  },
  mounted() {},
  methods: {
    async onload() {
      if (this.operationType=='edit') {
        //编辑门店商品
        await this.getGoodsView();
      } else {
        if (this.goodsId) {
          await this.getView();
        }
      }
    },
    back(){
    this.$router.go(-1);
    },
    previousStep(e) {
      this.active = "1";
    },
    async nextStep(stop) {
      if (stop === "1") {
        const dataOne = await this.$refs.createOne.submitForm();
        return new Promise((resolve, reject) => {
          if (dataOne) {
            this.active = "2";
            resolve();
          } else {
            reject();
          }
        });
      }
      if (stop === "2") {
        const dataTwo = await this.$refs.createTwo.submitForm();
        return new Promise((resolve, reject) => {
          if (dataTwo) {
            const { specsData, specsList } = dataTwo;
            const spec = specsList.map((item) => {
              return {
                id: item.id,
                childIds: item.checkList,
              };
            });
            this.formTwo = {
              specDetail: specsData,
              spec,
            };
            // console.log('this.formTwo',this.formTwo);
            resolve();
          } else {
            reject();
          }
        });
      }
    },
    async beforeLeave(route, stop) {
      if (stop === "1") {
        const dataOne = await this.$refs.createOne.submitForm();
        return new Promise((resolve, reject) => {
          if (dataOne) {
            // console.log('dataOne',dataOne);
            // this.$refs.createTwo.listSpecId(dataOne.supplierId);
            const { ...rest } = dataOne;
            this.formOne = {
              ...rest,
            };
            resolve();
          } else {
            reject();
          }
        });
      } else if (stop === "2") {
        if (route === "1") {
          const dataOne = await this.$refs.createOne.submitForm();
          return new Promise((resolve, reject) => {
            if (dataOne) {
              resolve();
            } else {
              reject();
            }
          });
        } else {
          const dataTwo = await this.$refs.createTwo.submitForm();
          return new Promise((resolve, reject) => {
            if (dataTwo) {
              const { specsData, specsList } = dataTwo;
              const spec = specsList.map((item) => {
                return {
                  id: item.id,
                  childIds: item.checkList,
                };
              });
              this.formTwo = {
                specDetail: specsData,
                spec,
              };
              // console.log('this.formTwo',this.formTwo);
              resolve();
            } else {
              reject();
            }
          });
        }
      }
    },
    async handleSubmit() {
      const createTwo = await this.$refs.createTwo.submitForm();
      if(!createTwo){
        return
      }

      if (this.id) {
        let obj = {
          ...this.formOne,
          skuList: createTwo.specsData,
          storeId: this.storeId,
          storeGoodsId: this.storeGoodsId,
          id:this.id,
          activityId:this.activityId,
          // limitBuyLeastNumMin: this.formOne.limitBuyLeastNumMin || 0,
          // limitBuyLeastNumMax: this.formOne.limitBuyLeastNumMax || 0,
          // saleNum: this.formOne.saleNum || 0,
          // limitBuyLeastDept: this.formOne.limitBuyLeastDept&&this.formOne.limitBuyLeastDept.length>0?this.formOne.limitBuyLeastDept : null,
        };
      //   console.log('this.formOne.limitBuyLeastDept',obj)
      //  return
        const { data } = await activityGoodsUp(obj);
        this.$message.success("修改成功");
        this.$router.go(-1);
      } else {
        let obj = {
          ...this.formOne,
          skuList: createTwo.specsData,
          storeId: this.storeId,
          storeGoodsId: this.storeGoodsId,
          activityId:this.activityId,
          // limitBuyLeastNumMin: this.formOne.limitBuyLeastNumMin || 0,
          // limitBuyLeastNumMax: this.formOne.limitBuyLeastNumMax || 0,
          // saleNum: this.formOne.saleNum || 0,
          // limitBuyLeastDept: this.formOne.limitBuyLeastDept&&this.formOne.limitBuyLeastDept.length>0?this.formOne.limitBuyLeastDept : null,
        };
        const { data } = await activityGoodsAdd(obj);
        this.$message.success("新增成功");
        this.$router.go(-1);
      }
    },
    handleBack() {
      this.$router.go(-1);
    },
    async getGoodsView() {
      const { data } = await activityView(this.id);
      // console.log('data',data);
      const { secondCategoryId, firstCategoryId, thirdCategoryId, ...rest } =
        data;
      // const specsIds =
      //   data.spec.length > 0
      //     ? data.spec.map((item) => {
      //         return item.id;
      //       })
      //     : [];

      // await this.$refs.createTwo.listSpecId();
      const formTwo = {
        specsData: data.skuList, 
      };
      await this.$refs.createTwo.setForm(formTwo);
      const formThree = {
        content: data.content,
      };
      if(data.limitBuyLeastDept){
        data.limitBuyLeastDept = JSON.parse(data.limitBuyLeastDept)
      }else{
        data.limitBuyLeastDept = []
      }
      this.formOne = data 
        this.$refs.createOne.setForm({...this.formOne});


    },
    async getView() {
      const { data } = await view(this.goodsId);
      // console.log('data',data);
      const { secondCategoryId, firstCategoryId, thirdCategoryId, ...rest } =
        data;
      const specsIds =
        data.spec.length > 0
          ? data.spec.map((item) => {
              return item.id;
            })
          : [];

      // await this.$refs.createTwo.listSpecId();
      const formTwo = {
        specsIds,
        specsData: data.specDetail,
        spec: data.spec,
      };
      this.formTwo = {
        spec: data.spec,
        specDetail: data.specDetail,
      };
      await this.$refs.createTwo.setForm(formTwo);
      const formThree = {
        content: data.content,
      };
      const formOne = {
        sort: data.sort,
      };
      this.formOne = {
        sort: data.sort,
      };
      this.$refs.createOne.setForm(formOne);
    },
  },
};
</script>

<style scoped></style>
