<template>
  <div>
    <avue-form
      ref="form"
      v-model="form"
      :option="formOption"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-after="uploadAfter"
      :upload-before="uploadBefore"
    >
    </avue-form>
  </div>
</template>

<script>
// import {listClassify, listSupplier} from "@/api/shop/goods";
import { goodsDeptTree } from "@/api/goods/goods";
import { goodsClassifyStoreList } from "@/api/store/goodsClassify";
import FormImgUpload from "@/components/formComponents/formImgUpload";
export default {
  name: "createOne",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      form: {
        status: 1,
      },
      deptList: [], //部门列表
      goodsClassifyList: [], //分组列表
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        column: [
          // {
          //   label: "最低限购数量",
          //   prop: "limitBuyLeastNumMin",
          //   maxlength: 5,
          //   showWordLimit: true,
          //   type: "number",
          //   span: 12,
          //   row: true,
          //   rules: [
          //     {
          //       required: false,
          //       message: "最低限购数量",
          //       trigger: "blur",
          //     },
          //   ],
          // },
          // {
          //   label: "最高限购数量",
          //   prop: "limitBuyLeastNumMax",
          //   maxlength: 5,
          //   showWordLimit: true,
          //   type: "number",
          //   span: 12,
          //   row: true,
          //   rules: [
          //     {
          //       required: false,
          //       message: "最高限购数量",
          //       trigger: "blur",
          //     },

          //   ],
          // },
          {
            label: "排序",
            prop: "sort",
            maxlength: 5,
            showWordLimit: true,
            type: "number",
            span: 12,
            row: true,
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
          },
          {
            label: "上下架状态",
            prop: "status",
            type: "select",
            dicData: [
              { id: 0, name: "下架" },
              { id: 1, name: "上架" },
            ],
            props: {
              label: "name",
              value: "id",
            },
            span: 12,
            row: true,
            rules: [
              {
                required: true,
                message: "请选择",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请选择"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          // {
          //   label: "是否必买商品",
          //   prop: "mustBuyStatus",
          //   type: "select",
          //   dicData: [
          //     { id: 0, name: "否" },
          //     { id: 1, name: "是" },
          //   ],
          //   props: {
          //     label: "name",
          //     value: "id",
          //   },
          //   span: 12,
          //   row: true,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择",
          //       trigger: "blur",
          //     },
          //     {
          //       validator: (rule, value, callback) => {
          //         if (value === "") {
          //           callback(new Error("请选择"));
          //         } else {
          //           callback();
          //         }
          //       },
          //     },
          //   ],
          // },
          // {
          //   label: "限购状态是否开启",
          //   prop: "limitBuyStatus",
          //   type: "select",
          //   dicData: [
          //     { id: 1, name: "是" },
          //     { id: 0, name: "否" },
          //   ],
          //   props: {
          //     label: "name",
          //     value: "id",
          //   },
          //   span: 12,
          //   row: true,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择",
          //       trigger: "blur",
          //     },
          //     {
          //       validator: (rule, value, callback) => {
          //         if (value === "") {
          //           callback(new Error("请选择"));
          //         } else {
          //           callback();
          //         }
          //       },
          //     },
          //   ],
          //   control: (val, form) => {
          //     if (val === 1) {
          //       return {
          //         limitBuyLeastDept: {
          //           display: true,
          //         },
          //         limitBuyLeastSex: {
          //           display: true,
          //         },
          //       };
          //     } else {
          //       return {
          //         limitBuyLeastDept: {
          //           display: false,
          //         },
          //         limitBuyLeastSex: {
          //           display: false,
          //         },
          //       };
          //     }
          //   },
          // },
          // {
          //   label: "部门",
          //   prop: "limitBuyLeastDept",
          //   type: "cascader",
          //   maxlength: 20,
          //   row: true,
          //   placeholder: "请输入",
          //   multiple: true,
          //   props: { multiple: true, label: "name", value: "id" },
          //   dicData: this.deptList,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择",
          //       trigger: "blur",
          //     },
          //   ],
          // },
          // {
          //   label: "限制性别",
          //   prop: "limitBuyLeastSex",
          //   type: "select",
          //   dicData: [
          //     { id: 1, name: "男" },
          //     { id: 2, name: "女" },
          //   ],
          //   props: {
          //     label: "name",
          //     value: "id",
          //   },
          //   span: 12,
          //   row: true,
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择",
          //       trigger: "blur",
          //     },
          //   ],
          // },
        ],
      },
    };
  },
  created() {
    this.getDept();
    // this.getGoodsClassify();
  },
  async mounted() {
    this.$emit("onload");
  },
  methods: {
    minNum() {
      return false;
    },
    async getDept() {
      const { data } = await goodsDeptTree();
      this.deptList = data;
      const arr = this.findObject(this.formOption.column, "limitBuyLeastDept");
      arr.dicData = data || [];
    },
    // async getGoodsClassify() {
    //   const { data } = await goodsClassifyStoreList();

    //   let l = data.filter((item) => {
    //     item.children =
    //       item.children &&
    //       item.children.filter((val) => {
    //         return val.children;
    //       });
    //     return item.children && item.children.length > 0;
    //   });

    //   this.formOption.column[4].dicData = l;
    // },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.mainImg = [...e];
      } else {
        this.form.mainImg = [...this.form.mainImg, e];
      }
    },
    //图片上传
    upload1(e) {
      if (Array.isArray(e)) {
        this.form.logoImg = [...e];
      } else {
        this.form.logoImg = [...this.form.logoImg, e];
      }
    },
    //图片上传
    upload2(e) {
      if (Array.isArray(e)) {
        this.form.bannerImg = [...e];
      } else {
        this.form.bannerImg = [...this.form.bannerImg, e];
      }
    },

    setForm(form) {
      console.log(form, "ffffff");
      this.form = form;
      this.form.limitBuyLeastSex = this.form.limitBuyLeastSex
        ? this.form.limitBuyLeastSex
        : "";
      console.log(this.form, "this.formthis.form");
    },
    submitForm() {
      return new Promise((resolve) => {
        this.$refs.form.validate((valid, done) => {
          if (valid) {
            done();
            resolve(this.form);
          } else {
            done();
            resolve(false);
          }
        });
      });
    },
    uploadDelete(file, column) {
      // return this.$confirm(`是否确定移除？`, "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      //   beforeClose: (action, instance, done) => {
      //     // console.log(action)
      //     if (action === "confirm") {
      //       let array = JSON.parse(
      //         JSON.stringify(this.form[column.prop].split(","))
      //       );
      //       let index = array.findIndex((item) => item === file.url);
      //       array.splice(index, 1);
      //       column.propsHttp.name = array.toString();
      //       if (array.length < column.limit) {
      //         this.upload("inline-block", column.prop);
      //       }
      //     }
      //     done();
      //   },
      // });
    },
    uploadError(error, column) {
      error && this.$message.error(error);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadAfter(res, done, loading, column) {
      done();
      // console.log(res)
      // console.log(column)
      // if (!res.path) {
      //   this.$message.error("上传失败");
      //   loading();
      // } else {
      //   if (column.prop === "banner") {
      //     if (column.propsHttp.name) {
      //       column.propsHttp.name += "," + res.path;
      //     } else {
      //       column.propsHttp.name = res.path;
      //     }
      //   } else {
      //     column.propsHttp.name = res.path;
      //   }
      //   console.log(column.propsHttp.name.split(",").length);
      //   if (column.propsHttp.name.split(",").length >= column.limit) {
      //     console.log(column.propsHttp.name.split(",").length);
      //     console.log(column.limit);
      //     this.upload("none");
      //   }
      // }
    },
    // upload(type) {
    //   let upload = document.getElementsByClassName("el-upload")[2];
    //   upload.style.display = type === "none" ? "none" : "inline-block";
    // },
  },
};
</script>

<style scoped>
/deep/ .avue-form__group .avue-upload__icon {
  width: 148px !important;
  height: 148px !important;
  line-height: 148px !important;
}
/deep/ .avue-form__group .avue-upload__avatar {
  width: 148px !important;
  height: 148px !important;
  object-fit: contain;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  object-fit: contain;
}
</style>
