var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"150px"}},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("批量设置")]),_c('el-table',{staticStyle:{"margin-bottom":"20px"},attrs:{"data":_vm.settingData}},[_c('el-table-column',{attrs:{"label":"*折扣率","prop":"discountRatio","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label-width":"0px"}},[_c('el-input',{attrs:{"placeholder":"请输入折扣率","onkeyup":"this.value=this.value.match(/\\d+\\.?\\d{0,2}/)","type":"text","clearable":"","max":1,"min":0.01},on:{"input":function($event){return _vm.settingValue($event, 'discountRatio', scope.$index, scope.row)}},model:{value:(scope.row.discountRatio),callback:function ($$v) {_vm.$set(scope.row, "discountRatio", $$v)},expression:"scope.row.discountRatio"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"*售价","prop":"salePrice","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"label-width":"0px"}},[_c('el-input',{attrs:{"placeholder":"请输入售价","onkeyup":"this.value=this.value.match(/\\d+\\.?\\d{0,2}/)","type":"text","clearable":""},on:{"input":function($event){return _vm.settingValue($event, 'salePrice')}},model:{value:(scope.row.salePrice),callback:function ($$v) {_vm.$set(scope.row, "salePrice", $$v)},expression:"scope.row.salePrice"}})],1)]}}])})],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.form.specsData,"border":"","header-cell-style":{
          background: '#eef1f6',
          color: '#606266',
          textAlign: 'center',
        }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',[_vm._v("暂无规格")])]},proxy:true}])},[_vm._l((_vm.form.specsList),function(item,index){return _c('el-table-column',{key:index,attrs:{"prop":("specs_" + index),"label":item.name,"width":"200px","align":"center"}})}),_c('el-table-column',{attrs:{"label":"规格图片","prop":"image","width":"170","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("specsData." + (scope.$index) + ".image"),"rules":[
                { required: true, message: '请上传图片', trigger: 'change' } ],"label-width":"0px","inline-message":true}},[_c('div',{staticClass:"skuImg"},[_c('FormImgUpload',{attrs:{"url":scope.row.image ? [scope.row.image] : [],"index":scope.$index,"limit":1,"maxSize":5,"disabled":"","accept":"image/jpeg,image/png,image/jpg","multiple":true},on:{"upload":function($event){return _vm.upload($event, scope.$index)}}})],1)])]}}])}),_c('el-table-column',{attrs:{"label":"*成本价","prop":"costPrice","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("specsData." + (scope.$index) + ".costPrice"),"rules":[
                { required: true, message: '请输入成本价', trigger: 'blur' } ],"label-width":"0px","inline-message":true}},[_c('el-input',{attrs:{"placeholder":"请输入成本价","onkeyup":"this.value=this.value.match(/\\d+\\.?\\d{0,2}/)","type":"text","disabled":"","clearable":""},on:{"blur":function($event){return _vm.salaryChange($event, 'costPrice', scope.$index)}},model:{value:(scope.row.costPrice),callback:function ($$v) {_vm.$set(scope.row, "costPrice", $$v)},expression:"scope.row.costPrice"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"*折扣率","prop":"discountRatio","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("specsData." + (scope.$index) + ".discountRatio"),"rules":[
                {
                  required: scope.row.discountType == 1 ? true : false,
                  // message: '请输入折扣率',
                  validator: _vm.discountRatioRule,
                  row: scope.row,
                  trigger: 'change',
                } ],"label-width":"0px","inline-message":true}},[_c('el-input',{attrs:{"placeholder":"请输入折扣率","onkeyup":"this.value=this.value.match(/\\d+\\.?\\d{0,2}/)","type":"text","clearable":"","max":1,"min":0.01},on:{"blur":function($event){return _vm.salaryChange($event, 'discountRatio', scope.$index)},"input":function($event){return _vm.salaryInput($event, 'discountRatio', scope.$index, scope.row)}},model:{value:(scope.row.discountRatio),callback:function ($$v) {_vm.$set(scope.row, "discountRatio", $$v)},expression:"scope.row.discountRatio"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"*吊牌价","prop":"originalPrice","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("specsData." + (scope.$index) + ".originalPrice"),"rules":[
                // { required: true, message: '请输入原价', trigger: 'blur' },
                {
                  required: true,
                  validator: _vm.originalPriceRule,
                  row: scope.row,
                  trigger: 'change',
                } ],"label-width":"0px","inline-message":true}},[_c('el-input',{attrs:{"placeholder":"请输入原价","disabled":"","onkeyup":"this.value=this.value.match(/\\d+\\.?\\d{0,2}/)","type":"text","clearable":""},on:{"input":function($event){return _vm.salaryInput($event, 'originalPrice', scope.$index, scope.row)},"blur":function($event){return _vm.salaryChange($event, 'originalPrice', scope.$index)}},model:{value:(scope.row.originalPrice),callback:function ($$v) {_vm.$set(scope.row, "originalPrice", $$v)},expression:"scope.row.originalPrice"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"*售价","prop":"salePrice","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-form-item',{attrs:{"prop":("specsData." + (scope.$index) + ".salePrice"),"rules":[
                // { required: true, message: '请输入售价', trigger: 'blur' },
                {
                  required: true,
                  validator: _vm.salePriceRule,
                  row: scope.row,
                  trigger: 'change',
                } ],"label-width":"0px","inline-message":true}},[_c('el-input',{attrs:{"disabled":scope.row.discountType == 1 ? false : true,"placeholder":"请输入售价","onkeyup":"this.value=this.value.match(/\\d+\\.?\\d{0,2}/)","type":"text","clearable":""},on:{"blur":function($event){return _vm.salaryChange($event, 'salePrice', scope.$index)},"input":function($event){return _vm.salaryInput($event, 'salePrice', scope.$index, scope.row)}},model:{value:(scope.row.salePrice),callback:function ($$v) {_vm.$set(scope.row, "salePrice", $$v)},expression:"scope.row.salePrice"}})],1)]}}])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }